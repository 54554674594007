<template>
  <div>
    <el-card>
      活动时间
      <el-date-picker
        v-model="form.value1"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      发布时间
      <el-date-picker
        v-model="form.value2"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
      标题
      <nav class="a1"><el-input v-model="form.value3"></el-input></nav>
      状态
      <el-select v-model="form.value4" placeholder="请选择">
        <el-option label="未审核" value="1"></el-option>
        <el-option label="已发布" value="2"></el-option>
        <el-option label="已审核" value="3"></el-option>
        <el-option label="未发布" value="4"></el-option>
      </el-select>
    </el-card>
    <el-card>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column  label="海报" width="180">
        </el-table-column>
        <el-table-column prop="name" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="people" label="报名人数"> </el-table-column>
        <el-table-column prop="date" label="发布时间"> </el-table-column>
        <el-table-column prop="address" label="活动时间"> </el-table-column>
        <el-table-column prop="address" label="状态"> </el-table-column>
        <el-table-column prop="address" label="操作"> </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        value1: '',
        value2: '',
        value3: '',
      },
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
      ],
    }
  },
  methods: {},
}
</script>

<style lang="less" scoped>
.el-tag {
  width: 100px;
}
.el-date-editor {
  margin-right: 30px;
  width: 200px;
}
.a1 {
  width: 200px;
  display: inline-block;
  margin-right: 30px;
}
</style>